@font-face {
  font-family: "Calibre";
  src: local("CalibreLight"), url("./fonts/CalibreLight.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Calibre";
  src: local("CalibreRegular"),
    url("./fonts/CalibreRegular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Calibre";
  src: local("CalibreMedium"),
    url("./fonts/CalibreMedium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Calibre";
  src: local("CalibreSemibold"),
    url("./fonts/CalibreSemibold.otf") format("opentype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Calibre";
  src: local("CalibreBold"), url("./fonts/CalibreBold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}
