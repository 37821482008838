@import "./assets/scss/variables";

@font-face {
  font-family: Calibre;
  src: url("./assets/scss/fonts/CalibreMedium.otf") format("opentype");
}

@font-face {
  font-family: Calibre;
  src: url("./assets/scss/fonts/CalibreRegular.otf") format("opentype");
}

body,
html {
  height: 100%;
  // font-family: $sans-serif-family;
  max-width: 100%;
  overflow-x: hidden;
}
