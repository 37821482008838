.footer {
  padding: 0px 0;

  &.footer-default {
    background-color: #f2f2f2;
  }

  nav {
    display: inline-block;
    float: left;
    padding-left: 7px;
  }

  ul {
    margin-bottom: 0;
    padding: 0;
    list-style: none;

    li {
      display: inline-block;

      a {
        color: inherit;
        padding: $padding-base-vertical;
        font-size: $font-size-small;
        text-transform: uppercase;
        text-decoration: none;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  .copyright {
    font-size: $font-size-small;
    line-height: 1.8;
  }

  &:after {
    display: table;
    clear: both;
    content: " ";
  }
}

.footer-home {
  padding-top: 40px;

  .container .row .col-12 {
    margin-bottom: 100px;
  }

  @media screen and (max-width: 576px) {

    .container .row .col-12 {
      margin-bottom: 50px;
    }

  }

  .left-item{
    .btn{
      font-weight: bold;
    }
  }

  .footer {
    margin-bottom: -25px;
    background-color: #27A25A;
  }

  .copy-right {
    color: white;
    margin: 0 auto;
    text-align: center;
    font-size:12px
  }

  .list-group.list-group-flush > .list-group-item {
    border: none;
  }
}