.card.elevated {
  box-shadow: $box-shadow;
}

.card {
  border: 0;
  border-radius: $border-radius-small;
  display: inline-block;
  position: relative;
  width: 100%;
  margin-bottom: 20px;

  .card-body {
    padding: 15px 15px 10px 15px;

    &.table-full-width {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .card-header {
    &:not([data-background-color]) {
      background-color: transparent;
    }

    padding: 15px 15px 0;
    border: 0;

    .card-title {
      margin-top: 10px;
    }
  }

  &.card-wizard {
    .card-header {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 40px;
    }
  }

  .map {
    height: 500px;
    border-radius: $border-radius-small;

    &.map-big {
      height: 400px;
    }
  }

  &[data-background-color="orange"] {
    background-color: $primary-color;

    .card-header {
      background-color: $primary-color;
    }

    .card-footer {
      .stats {
        color: $white-color;
      }
    }
  }

  &[data-background-color="red"] {
    background-color: $danger-color;
  }

  &[data-background-color="yellow"] {
    background-color: $warning-color;
  }

  &[data-background-color="blue"] {
    background-color: $info-color;
  }

  &[data-background-color="green"] {
    background-color: $success-color;
  }

  .image {
    overflow: hidden;
    height: 200px;
    position: relative;
  }

  .avatar {
    width: 30px;
    height: 30px;
    overflow: hidden;
    border-radius: 50%;
    margin-bottom: 15px;
  }

  .info-area {
    padding: 40px 0 40px;
    text-align: center;
    position: relative;
    z-index: 2;

  }

  label {
    font-size: $font-size-small;
    margin-bottom: 5px;
    color: $dark-gray;
  }

  h5 {
    color: $thrive-color;
  }

  .ct-chart {
    margin: 30px 0 30px;
  }

  .card-footer {
    background-color: transparent;
    border: 0;

    .stats {
      i {
        margin-right: 5px;
        position: relative;
        top: 2px;
      }
    }

    .btn {
      margin: 0;
    }
  }
}

.card-user {
  .image {
    height: 120px;
  }

  .author {
    text-align: center;
    text-transform: none;
    margin-top: -77px;

    a+p.description {
      margin-top: -7px;
    }
  }

  .avatar {
    width: 124px;
    height: 124px;
    border: 1px solid $white-color;
    position: relative;
  }

  .card-body {
    min-height: 240px;
  }

  hr {
    margin: 5px 15px;
  }

  .button-container {
    margin-bottom: 6px;
    text-align: center;
  }
}

.card-plain {
  background: transparent;
  box-shadow: none;

  .card-header,
  .card-footer {
    margin-left: 0;
    margin-right: 0;
    background-color: transparent;
  }

  &:not(.card-subcategories).card-body {
    padding-left: 0;
    padding-right: 0;
  }
}

.card-chart {
  .card-header {
    .card-title {
      margin-top: 10px;
      margin-bottom: 0;
    }

    .card-category {
      margin-bottom: 5px;
    }
  }

  .table {
    margin-bottom: 0;

    td {
      border-top: none;
      border-bottom: 1px solid #e9ecef;
    }
  }

  .chart-area {
    height: 190px;
    width: calc(100% + 30px);
    margin-left: -15px;
    margin-right: -15px;
  }

  .card-footer {
    margin-top: 15px;

    .stats {
      color: $dark-gray;
    }
  }

  .dropdown {
    position: absolute;
    right: 20px;
    top: 20px;

    .btn {
      margin: 0;
    }
  }
}


.card-collapse {
  .card {
    margin-bottom: 25px;

    .card-header {
      position: relative;
      padding: .75rem 1.25rem;
      padding-left: 0;
      padding-right: 0;
      font-weight: bold;

      a[data-toggle="collapse"] {
        display: block;
        color: $light-black;

        i {
          float: right;
          position: relative;
          color: $primary-color;
          top: 1px;
          @extend .animation-transition-general;
        }
      }

      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: $light-gray;
      }
    }

    .card-body {
      padding-left: .5rem;
      padding-right: .5rem;
    }
  }
}