@import "../../assets/scss/calibre";
@import "../../assets/scss/mixins/cover-img";

.bg-Img {
  @include cover-img;
}

.bg-Img {
  @include cover-img;
}

.no-bottom-margin {
  margin-bottom: 0rem;
}
.foot-info {
  font-size: 40px;
  line-height: 55px;
  font-weight: 500;
  padding-left: 60px !important;
  font-family: "Calibre";
}
.login-bottom-spacing {
  margin-bottom: 85px;
}
.cover-img-text {
  margin-top: 125px;
}
.logo-spacing {
  margin-left: 60px;
}
.logo-container {
  margin-top: 40px;
}
.login-form-wrapper {
  margin-top: 2rem;
}
@media (min-width: 1422px) {
  .cover-img-text {
    margin-top: 410px;
  }
}
@media (min-width: 1600px) {
  .cover-img-text {
    margin-top: 535px;
  }
}
@media (min-width: 1706px) {
  .cover-img-text {
    margin-top: 595px;
  }
  .logo-spacing {
    margin-left: 60px;
  }
  .logo-container {
    margin-top: 40px;
  }
  .login-bottom-spacing {
    margin-bottom: 140px;
  }
}

.foot-body {
  padding-left: 60px !important;
  font-family: "Calibre";
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
}
