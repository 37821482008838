.dateRangePicker{
    display: none;
}
.dateRange{
    border:none;
    background-color: white;
    position: relative;
    border-radius: 5px;
    box-shadow: 2px 2px .5rem  rgba($color: #000000, $alpha: .1);
}
.dateRange:focus-within .dateRangePicker{
    display: block;
}