@import "../../../assets/scss/variables";

.form-header {
  font-size: 37px;
  letter-spacing: 0px;
}

.auth-label-size {
  font-size: $font-size-1;
}

.forgot-pword {
  color: $border-color-gray-2;
  font-size: 18px;
}

.form-icon {
  color: $form-icon-color-1;
}

.copyright {
  font-size: 16px;
}

.login-btn {
  background: #3bc552 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  border-radius: 0px 8px !important;
  width: 199px;
  height: 53px;
  text-decoration: none;
  border: none !important;
  outline: none !important;
}
