@import "./variables";
@import "./mixins";

// Plugins CSS
@import "./plugins/plugin-react-bootstrap-switch";
@import "./plugins/plugin-animate-bootstrap-notify";
@import "./plugins/plugin-bootstrap-switch";
@import "./plugins/plugin-perfect-scrollbar";

// Core CSS
@import "./buttons";
@import "./inputs";
@import "./typography";
@import "./misc";
@import "./checkboxes-radio";
// components
@import "./navbar";
@import "./dropdown";
@import "./alerts";
@import "./images";
@import "./nucleo-outline";
@import "./calibre";
@import "./tables";
@import "./sidebar-and-main-panel";
@import "./cards";
@import "./footers";
@import "./fixed-plugin";
@import "./sections";
@import "./responsive";
@import "./home";
@import "./pagination";
@import "./dateRangePicker";
// Calibre fonts start
.ca-font-medium {
  font-family: "Calibre" !important;
  font-weight: 500 !important;
}

.ca-font-regular {
  font-family: "Calibre" !important;
  font-weight: 400 !important;
}

// Calibre fonts end

.tab-font-size {
  font-size: 22px;
}
.modal-header {
  border-bottom: none;
}
.modal-title {
  margin-left: 33px;
}
.sub-modal-header {
  margin-left: 13px;
  margin-bottom: -13px;
}

.farmer-edit-spacing {
  margin-left: 25px;
}
.sub-total-container {
  // margin-top: 200px;
  padding-right: 55px;
  padding-left: 36px;
}
.sub-modal-footer {
  margin-bottom: 5px;
}

.modal-header-subcription {
  margin-right: 342px;
}

.show-password {
  &:hover {
    cursor: pointer;
  }
}

.view-profile-subheader {
  font-size: 14px;
  color: #b5b5b5;
}

.view-profile-fieldstyle {
  font-size: 18px;
}

.hide-input {
  -webkit-appearance: none;
  border: 0;
  background-color: transparent;
  text-align: center;
  outline: none;
}
.hr-style-top {
  margin-top: 0rem;
  margin-bottom: 0rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.login-form-style {
}

.card-size {
  max-width: 299px;
  width: 182.13px;
  height: 200px;
  width: 200px;
}

.hr-style {
  margin-top: 0rem;
  margin-bottom: 0rem;
}
.hr-style-2 {
  margin-top: 0rem;
  margin-bottom: 0rem;
  // border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.sub-total-header {
  color: #b5b5b5;
  margin-right: 105px !important;
}
.remove-btn-style {
  font-size: 25px;
  color: #b5b5b5;
  outline: none;
  border: none;
  background-color: transparent;
  &:focus {
    color: #3bc552;
    border-bottom: 1px solid #3bc552;
    outline: none;
  }
}
.default-tab-color {
  color: #3bc552;
  border-bottom: 1px solid #3bc552;
  outline: none;
}
.header-text {
  text-align: center;
}
.warehouse-header {
  font-size: 11px !important;
  text-align: center;
}
.center {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.center:hover {
  color: white;
  text-decoration: none;
}
.form-btn-1 {
  @include btn-style2(#3bc552, 40px, 150px);
  margin-right: 25px;
}
.form-btn-2 {
  @include btn-style2(#ffffff, 40px, 150px);
  color: #3bc552;
  border: 1px solid #3bc552 !important;
  &:hover {
    color: #3bc552;
  }
  &:focus {
    color: #3bc552;
  }
}
.form-header-color {
  color: #3bc552 !important;
}
.nav-icons {
  color: $nav-icon-color;
  width: 52px;
  height: 22px;
}
.vert-line {
  position: absolute;
  top: 20px;
  border-left: 1px solid #00000012;
  height: 30px;
}

.vert-line-2 {
  position: absolute;
  top: 20px;
  left: 160px;
  border-left: 1px solid #00000012;
  height: 30px;
}

.overview-modal {
  margin-top: 3em;
  width: 802px;
  // height: 826px;
}
.total-input {
  border: white;
  font-size: 16px;
  margin-right: 12px;
}
.subscription-modal-first {
  margin-top: 12em;
  width: 802px;
  height: 414.68px;
}
.subscription-modal-admin {
  margin-top: 10em;
  width: 802px;
  height: 477.68px;
}

.modal-position {
  left: -85px;
}

.overview-form-field {
  width: 100%;
  height: 40px;
  border-radius: 8px;
}

.label-style {
  margin-bottom: 0.1rem;
}
.sub-input-header {
  background-color: #f7f7f7;
  border: 1px solid #f7f7f7;
  padding-top: 8px;
  padding-bottom: 8px;
  color: #b5b5b5;
  border-radius: 3px;
  margin-top: 14px;
  margin-left: 20px;
  margin-right: 40px;
}
.trash-icon {
  &:hover {
    cursor: pointer;
  }
}
.sub-input-body {
  margin-top: 8px;
  margin-bottom: 8px;
  margin-left: 20px;
  margin-right: 30px;
  font-size: 18px;
  height: 266.7px;
  max-height: 266.7px;
}
.subscription-form-field {
  width: 307.5px;
  height: 40px;
  border-radius: 7px;
  background: #f7fffa 0% 0% no-repeat padding-box;
  border: 1px solid #8191a9;
}
.subscription-form-field-2 {
  width: 195px;
  height: 40px;
  border-radius: 7px;
  background: #f7fffa 0% 0% no-repeat padding-box;
  border: 1px solid #8191a9;
}
.modal-header {
  background-color: #eefff4;
}
.modal-footer {
  background-color: #eefff4;
}
.close-btn {
  &:hover {
    cursor: pointer;
  }
}
.subscription-btn {
  width: 110px !important;
  height: 29px !important;
  border-radius: 7px !important;
  font-size: 17px;
  text-align: center;
  padding: 0px !important;
}
.dot-online {
  position: absolute;
  // top: 33px;
  left: 38px;
  height: 12px;
  width: 12px;
  background-color: #3bc552;
  border-radius: 50%;
  display: inline-block;
}
.input-group-append .btn {
  margin: 0px;
  background-color: #f7fffa;
  color: #3bc552;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-top-right-radius: 0.3rem !important;
  border-bottom-right-radius: 0.3rem !important;
  &:focus {
    color: #f7fffa;
  }
}
// .addon-style {
//   color: blue;
//   margin: 0px;
// }

.overview-content {
  margin-top: $content-area-height;
}

.navbarShow {
  z-index: 1;
}

td {
  vertical-align: middle !important;
  padding-right: 10px !important;
  padding-left: 10px !important;
}

.drop-menu {
  z-index: 1;
  .menu-opt {
    &:hover {
      background-color: #3bc55224;
      color: #3bc552;
      cursor: pointer;
    }
  }
}
.toast-style {
  text-align: center;
  border-radius: 2px;
  font-size: 17px;
}

.error-page {
  margin-top: 91px;
}

.dot-message {
  position: absolute;
  top: 24px;
  left: 57px;
  height: 8px;
  width: 8px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
}
.dot-bell {
  position: absolute;
  top: 24px;
  left: 130px;
  height: 8px;
  width: 8px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
}

.search {
  background-color: #ffffff;
  width: auto;
  height: 64px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;

  padding-right: 30px;
  margin-top: 15px;
  .add-entry-btn {
    color: $white-color;
    background-color: $nav-icon-color;
    border-radius: 8px;
    width: 40px;
    height: 40px;
    &:hover {
      cursor: pointer;
    }
  }

  .search-form-style {
    width: 280px;
    height: 40px;
    border-radius: 8px;
    outline: none;
    border: 1px solid #707070;
    padding-left: 25px;
    &::-webkit-input-placeholder {
      padding-left: 31px;
    }
  }
}
.filter-container {
  display:flex;
  justify-content: center;
  align-items: center;
}
.select-search {
  margin-left: 10px;
  height: 40px;
  border-radius: 8px;
  outline: none;
  border: 1px solid #707070;
}

.select-search:focus {
  outline: none;
  border: 1px solid #000;
}

.search-container {
  padding-right: 170px;
  padding-left: 115px;
}

// code from old project
// body {
//     max-width: 100%;
//     overflow-x: hidden;
//   }

.font-weight-boldish {
  font-weight: 500;
}

.font-weight-bold {
  font-weight: 700;
  color: #000000;
}

.farmer-onboarded {
  @include card-icon(#3bc55224);
}
.farmer-input {
  @include card-icon(#005aff24);
}
.farmer-output {
  @include card-icon(#ff3d5724);
}
.total-revenue {
  @include card-icon(#3bc55224);
}

.card-font-size {
  font-size: 30px;
}

.chart-space {
  margin-left: 82px;
  margin-right: 150px;
}

.card-right-distance {
  margin-right: 35.85px;
}

.card-subtitle-1 {
  color: #b5b5b5;
  font-size: 14px;
  text-align: center;
}

.card-text-container {
  padding-left: 0px;
}

.tab-underscore a {
  color: $dark-green-color;
}

.tab-underscore a:hover {
  color: $secondary-color;
}

.tab-underscore .active {
  border-bottom: 3px solid $secondary-color;
  color: $secondary-color;
}

.bg-grey {
  background-color: #f3f6f4;
  background-image: none !important;
}

.bottom-right {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0 60px 12px 0;
}

.th-card-header {
  padding-top: 80px;
  margin-bottom: 20px;

  h6 {
    margin-bottom: 60px;
    margin-top: 20px;
  }

  > div:not(:last-child) {
    border-right: 1px solid #ebebf1;
  }
}

.credit-items {
  margin-top: 30px;
  background-color: #ebebf1;
  padding-right: 30px;
  right: -30px;
  bottom: -30px;

  > h6 {
    margin-left: 10%;
    padding: 30px 0 20px 0;
  }

  .form-check .form-check-sign::before,
  .form-check .form-check-sign::after {
    background-color: #ffffff;
  }
}

.mt-100 {
  margin-top: 100px;
}

.max-height {
  height: stretch;
}

.progressbar-def {
  height: 4px;
}

.bg-primary-hover :hover {
  background-color: #e8f9ef;
}

.bg-primary-lite {
  background-color: #e8f9ef;
}

.bg-primary-lite ol {
  background-color: #e8f9ef;
}

.bg-dark-green {
  background-color: $dark-green-color;
}

.breadcrumb-item + .breadcrumb-item::before {
  color: $primary-color;
  content: ">";
}

.no-text-decoration {
  text-decoration: none;
}

.no-text-decoration:hover {
  text-decoration: none;
}

// position checkbox
.top-25 > label > span.form-check-sign::before,
.top-25 > label > span.form-check-sign::after {
  top: 25%;
}

.bg-lite-grey {
  background-color: #ebecf1;
}

.input-rect {
  border: 0;
  border-bottom: 1px solid;
  border-bottom-color: #e3e3e3;
  border-radius: 0px;
  padding: 0px;
  margin-top: 5px;
}

.navbar-logo-position {
  margin-left: 3px;
  margin-top: 19px;
  margin-bottom: 13px;
}

.bg-tone-1 {
  background-color: $thrive-tone-1;
}

.bg-tone-2 {
  background-color: $thrive-tone-2;
}

.text-tone-1 {
  color: $thrive-tone-1 !important;
}

.text-tone-2 {
  color: $thrive-tone-2 !important;
}

.input-rect:focus {
  border: 0;
  border-bottom: 1px solid;
  border-bottom-color: #21c570;
}

.btn-lite-primary {
  color: #ffffff;
  background-color: #e8f9ef;
}
.backed-color {
  color: #fff !important;
}
.bg-facebook {
  background-color: $social-facebook;
}

.bg-google {
  background-color: $social-google;
}

input[type="date"]:before {
  content: attr(placeholder) !important;
  color: $medium-gray;
  margin-right: 0.5em;
}

input[type="date"]:focus:before,
input[type="date"]:valid:before {
  content: "";
}

.max-width-150 {
  max-width: 150px;
}

.notify-width {
  width: 45px;
}

.img-circle {
  border-radius: 50%;
}
.img-square {
  border-radius: 8px;
  display: flex;
margin-left: auto;
margin-right: auto;
}

.border-10 {
  border-width: 10px !important;
  border-style: solid;
}

.overflow-hidden {
  overflow: hidden;
}

.resp-iframe {
  width: 100%;
  height: 100%;
}

.notify-width {
  width: 45px;
}
.farmer-img-info {
  display: inline-block;
  font-size: 20px;
}
.sub-header-form {
  font-size: 18px;
  line-height: 26px;
}
.carousel-caption {
  background-color: rgba(6, 58, 28, 0.2);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -15%);
  width: max-content;
  padding: 1em;
  right: unset;
  bottom: unset;
}

@media (max-width: 576px) {
  .carousel-caption {
    width: 70%;
    top: 45%;
    left: unset;
    right: 0%;
    transform: translateY(-50%);
  }

  .hero-text {
    font-size: 35px;
  }
}

.farm-card-img {
  width: 250px;
  height: 200px;
  overflow: hidden;
  text-align: center;

  img {
    height: 100%;
    max-width: unset;
    width: unset;
    text-align: center;
  }
}

.carousel-indicators .active {
  background-color: $thrive-tone-1;
}

.carousel-indicators li {
  border-radius: 50%;
  width: 4px;
  height: 4px;
  background-color: #707070;
}

.carousel-indicators {
  bottom: -50px;
}

.green-gradient {
  background-image: linear-gradient(164deg, #90cc41, #21954a);
  // background-color: #fff;
}

.faq-active > li > a {
  border-top: 1px solid gainsboro;
  // font-weight: bold;
  // color: $primary-color;
}

.faq-active > li > a.active {
  border-top: 2px solid $primary-color;
  // font-weight: bold;
  color: $primary-color;
}

.faq-active > li > a.active:hover {
  border-top: 2px solid $primary-color;
  // font-weight: bold;
  color: $primary-color;
}

.hamburger {
  padding: 10px 10px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}

@media (min-width: 992px) {
  .navbar-expand-lg .hamburger {
    display: none;
  }
}

.hamburger:hover {
  opacity: 0.7;
}

.hamburger.is-active:hover {
  opacity: 0.7;
}

.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
  background-color: #707070;
}

.hamburger-box {
  width: 30px;
  height: 24px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
}

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  width: 30px;
  height: 4px;
  background-color: #707070;
  outline: none;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}

.hamburger-inner::before,
.hamburger-inner::after {
  content: "";
  display: block;
}

.hamburger--spring .hamburger-inner {
  top: 2px;
  transition: background-color 0s 0.13s linear;
}

.hamburger--spring .hamburger-inner::before {
  top: 10px;
  width: 24px;

  transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
    width 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
    transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spring .hamburger-inner::after {
  top: 20px;
  width: 21px;

  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
    width 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
    transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spring.is-active .hamburger-inner {
  transition-delay: 0.22s;
  background-color: transparent !important;
  outline: none;
}

.hamburger--spring.is-active .hamburger-inner::before {
  top: 0;
  width: 30px;
  transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
    width 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
    transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translate3d(0, 10px, 0) rotate(45deg);
}

.hamburger--spring.is-active .hamburger-inner::after {
  top: 0;
  width: 30px;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
    width 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
    transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translate3d(0, 10px, 0) rotate(-45deg);
}

.navbar-collapse {
  padding-top: 0.625rem;
}

.bg-transluscent {
  background-color: #ffffffd6;
}

.icon-parent {
  border-color: #4b8401;
  border-style: solid;
  width: 78px;
  height: 78px;
  border-radius: 50%;
  background-color: white;
}

.icon {
  position: relative;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  height: 35px;
  transform: translate(-50%, -50%);
}

.icon-new {
  height: 90px;
}

// loader start

.circle-loader {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 70px;
  height: 70px;
  margin: -75px 0 0 -75px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #91cc42;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.circle-loader:before {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #91cc42;
  -webkit-animation: spin 3s linear infinite;
  animation: spin 3s linear infinite;
}

.circle-loader:after {
  content: "";
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #91cc42;
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

// loader end

//loader small
.small-loader {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 50px;
  height: 50px;
  margin: -75px 0 0 -75px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #22964b;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.small-loader:before {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #27a25a;
  -webkit-animation: spin 3s linear infinite;
  animation: spin 3s linear infinite;
}

.small-loader:after {
  content: "";
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #9acb55;
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.badge-tag {
  margin-top: -50px;
  border-left: 10px solid $thrive-tone-2;
  background-color: $thrive-tone-1;
}
.spinner {
  width: 48px;
  height: 16px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  padding-top: 2px;
}

.spinner > div {
  width: 12px;
  height: 12px;
  background-color: #91cc42;
  margin-left: 4px;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}
.spinner_date {
  width: 48px;
  height: 16px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  padding-top: 2px;
}
.spinner_date > div {
  width: 12px;
  height: 12px;
  background-color: #fff;
  margin-left: 4px;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}
.spinner_date .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner_date .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

// Doz new entry
.top-border-th {
  z-index: 1999;
  display: block;
  width: 100%;
  height: 4px;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  // background-image: -webkit-linear-gradient(286deg, #90cc41, #21954a);
  // background-image: linear-gradient(164deg, #90cc41, #21954a);
}
.green-text {
  color: #90cc42;
  font-weight: 600;
}
//hover
.green-text:hover {
  color: #90cc42;
}
.navbar-img {
  max-width: none;
}

// .paymentImage{
//   // max-width: 124px;
//   // text-align: center;
//   // max-width: 187px;
//   // margin-left: 52px
//   // max-width: 58%;
// }

.transferButton {
  // alignItems: center;
  margin-left: 30px;
  // min-width: 0;
  // margin-right: -26%
  // padding-left: 12px;
}

@media (width: "320px") {
  .transferText {
    max-width: 103px;
  }
}
@media (max-width: 812px) {
  .transferButton {
    min-width: 162px;
    margin-left: 21% !important;
    margin-right: 21% !important;
  }
  .navbar-logo-spacing {
    margin-left: 0rem !important;
  }
  .vert-line,
  .vert-line-2 {
    display: none;
  }
  .login-container {
    margin-left: 0rem !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .sign-cover-img {
    display: none;
  }
  .dot-message {
    top: 25px;
    // left: 53px;
  }
  .dot-bell {
    top: 80px;
    left: 53px;
  }
  .chart-space {
    margin-left: 0px;
    margin-right: 0px;
  }
  .card-right-distance {
    margin-right: 0px;
  }
  .search-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 25px;
  }
}

// .orText {
//   margin-left: 30px;
// }

@media (width: "320px") {
  .paymentImage {
    max-width: 103px;
  }
}
@media (max-width: "812px") {
  .payButton {
    // margin-left: 30%
    //width:162px;
    // margin-left:21% !important;
    // margin-right: 21% !important;
  }
}
.filterAll {
  background: #42c864;
  color: #fff;
  padding: 5px 15px;
}
.filterOpen {
  background: #fff;
  padding: 5px 15px;
}
.filterClose {
  background: #fff;
  padding: 5px 15px;
}
.sidebar,
.off-canvas-sidebar,
.sidebar .sidebar-wrapper,
.off-canvas-sidebar .sidebar-wrapper {
  width: 210px;
}
.fundedTransactions h4 {
  color: #90cc42;
  font-weight: 700;
}
// .fundedTransactions, .FarmDetails, .FarmDetails {background-color: rgba(0,0,0,0.6)}
.fundedFarmSection .card-body {
  background-color: #000;
}
.fundedFarmSection .font-weight-bold {
  color: #37a751;
}
.fundedFarmSection .text-muted {
  color: #a3b1bd !important;
  font-size: 14px;
  font-weight: 600;
}

.welcome-message {
  color: #90cc42;
}
.fundedListImage {
  height: 20px;
  background-color: #32a754;
  position: relative;
  top: 5px;
  margin: 0 !important;
}
.investmentCards {
  text-align: center;
  margin-bottom: 0;
  padding: 0 !important;
  span {
    color: #fff;
    font-size: 25px;
    font-weight: 500;
  }
  .card-body {
    height: 150px;
    position: relative;
    max-height: 150px;
    min-height: 150px;
    background-color: rgba(0, 0, 0, 0.7);
  }
  h5 {
    padding-top: 25px;
  }
}
.userDashboardBg {
  //background-image: url(/static/media/homepage1.196caf2c.png);
  height: 100vh;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
  overflow: scroll;

  .pageWrapper {
    background-color: #fafafa;
    height: 100%;
  }
}

.fundedFarmList ul {
  height: 104px;
  overflow: scroll;
}
.farmLoader {
  height: 170px;
  margin: 0px !important;
  margin-bottom: 20px !important;
  width: 100%;
}
.about-section,
.team-section {
  position: relative;
  top: 8%;
}
.recognised {
  > div {
    margin-bottom: 20px;
  }
}
.fixed-top {
  position: fixed !important;
  //background-color: rgba(0,0,0,0.3);
  background-color: #fff;
  height: 90px;
}
.footer-social {
  a {
    margin-right: 8px;
  }
}
.navbar .navbar-nav.home .nav-link {
  border-radius: 30px;
  font-size: 14px;
  color: #000;
  font-weight: 600;
  //background-color: #fff
}
// .main-panel{
//   width:calc(100% - 210px);
// }
.transactionTableHead {
  font-size: 10px;
  font-weight: bold;
}

.homeImageSize {
  width: 100%;
  height: 200px;
  object-fit: cover;
  //filter:grayscale(70%)
}

.homeFarmUpdateImageSize {
  width: 100%;
  height: 300px;
  object-fit: scale-down;
}
.faq-active a {
  cursor: pointer;
}
.faq-active li:hover a {
  color: #4b8401;
}

//Homepage
.homeBannerText {
  text-shadow: rgb(93, 92, 91) 1px 0px 10px;
  font-size: 20px;
  font-weight: 500;
}
.homBannerCover {
  background-color: #063a1cd1;
}
.homeBannerTitle {
  text-shadow: #5d5c5b 1px 0 10px;
  .colorGreen {
    //color: rgb(35, 162, 75);
    color: rgb(255, 255, 255);
  }
}
.homeBannerWrapper {
  position: relative;
  top: 20%;
  width: 50%;
  margin-top: 90px;
  font-size: 1.4em;
}
.homeBannerMobileWrapper {
  position: relative;
  top: 33%;
  font-size: 1.1em;
}

.farm-stage-tag {
  position: absolute;
  //background-color: #21a14b;
  padding: 4px 15px;
  color: #ff0000;
  font-size: 16px;
  font-weight: 800;
  font-style: italic;
  //border-left: 7px solid #90CC42;
  top: 2%;
  right: -5px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
  min-width: 100px;
  text-align: center;
}
.hidden {
  display: none !important;
}
//.navbar-collapse{
// background-color: rgba(0, 0, 0, 0.7);

//}
.thrive-social {
  position: absolute;
  top: 5px;
  right: 4px;
  width: 30px;
  max-height: 100px;

  a {
    display: block;
    font-size: 25px;
    margin-bottom: 10px;
    color: #fff;
  }
  a:hover {
    color: #45bb62;
  }
}
.box-wrapper {
  text-align: center;
}
.img-wrapper {
  position: absolute;
  top: -41px;
  left: 0;
  // text-align: ;
}
.text-wrapper {
  padding-top: 30px;
  font-size: 14px !important;
  text-align: left;
}
@media (width: 768px) {
  .text-wrapper {
    font-size: 8px !important;
  }
}
.homeBannerCoverMobile {
  background-color: rgba(0, 0, 0, 0.3);
  text-align: center;
  position: absolute;
  top: 90px;
  height: calc(80vh - 92px);
  left: 0;
  margin: 0px !important;
  width: 100%;
}
.homeBannerCoverWeb {
  background-color: rgba(49, 53, 51, 0.25);
  text-align: left;
  position: absolute;
  top: 0;
  height: 100%;
  left: 0;
  padding: 0;
  width: 100%;
}
.ReactTable .rt-thead .rt-resizable-header-content {
  font-weight: 600;
  padding: 10px 5px;
}
.dashboardModel .modal-content {
  background-image: -webkit-linear-gradient(286deg, #90cc41, #21954a);
  text-align: center;
  .modal-header {
    border-bottom: 0px;
  }
}

.FarmDetails .panel-header {
  padding: 20px 20px 0px 20px;
}
.FarmDetails .farmInfo {
  margin: 20px auto;
}
.FarmDetails .pageLoader {
  position: relative;
  text-align: center;
  color: #fff;
  font-size: 18px;
  font-weight: 600;

  p {
    margin: 30px auto;
  }

  .circle-loader {
    margin: 39px 0 0 -50px;
    width: 80px;
    height: 80px;
  }
}
.alert.alert-success {
  background-color: #2ba44b !important;
}

.button-wrapper {
  a {
    display: inline-block;
  }
}
.dashboardModel {
  max-width: 400px;
  .modal-body-wrapper {
    top: -30px;
  }
}

//Ends here
@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

// object must have width before class below will work i.e.
// display must be block or inline-block
.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  // overflow: hidden;
}

.truncate-multi {
  overflow: hidden;
  position: relative;
}

.truncate-multi:after {
  content: "";
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 70%;
  height: 1.5em;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1) 50%
  );
}
// AY
.btn-round {
  background-color: #90cc42;
}
.text-dark-green {
  color: #ffffff !important;
}
.btn-round:hover {
  background-color: #b5e378;
}
.fundFarm {
  background-color: #90cc42 !important;
  color: #ffffff !important;
}
.fundFarm:hover {
  background-color: #b5e378 !important;
}

.text-left {
  text-align: center !important;
}
.funders {
  text-align: center !important;
}
.dashboard {
  color: #ffffff !important;
}
.dashboard:hover {
  background-color: #b5e378 !important;
}
@media (max-width: 767px) {
  .homeBannerCoverMobile {
    top: 88px;
    height: calc(80.7vh - 92px);
  }
}
@media (max-width: 360px) {
  .homeBannerMobileWrapper {
    top: 12%;
  }
}
@media (width: 360px) {
  .homeBannerCoverMobile {
    height: calc(81vh - 92px);
  }
}
@media (width: 411px) {
  .homeBannerCoverMobile {
    height: calc(80.6vh - 92px);
  }
}
@media (max-width: 320px) {
  .homeBannerMobileWrapper {
    top: 1%;
  }
}
@media (width: 320px) {
  .homeBannerCoverMobile {
    height: calc(80.8vh - 92px);
  }
}
@media (width: 375px) {
  .homeBannerCoverMobile {
    height: calc(80.7vh - 92px);
  }
}
@media (width: 812px) {
  .homeBannerCoverMobile {
    height: calc(80.6vh - 92px);
  }
}
//footer
.left-item {
  padding: 50px;
}
.morelinks {
  padding-left: 0px !important;
}
//.footer-home{
//padding-left: 10px!important;
//}
@media (width: 768px) {
  .footerHome {
    font-size: 10px !important;
  }
}
// signIn page
.btn-primary:not(:disabled):not(.disabled):active {
  background-color: #3bc552 !important;
  box-shadow: none !important;
}

.btn-secondary:not(:disabled):not(.disabled):active {
  background-color: #3bc552 !important;
  box-shadow: none !important;
}
.btn.btn-link {
  color: #3bc552 !important;
}
.btn.btn-link:active {
  color: #3bc552 !important;
}
.btn.btn-link:hover {
  color: #b5e378 !important;
}
.btn-primary:focus {
  background-color: #3bc552 !important;
}
.text-anger {
  color: red;
  display: flex;
  padding-left: 15px;
}
.dropDown {
  position: relative;
  font-size: 25px;
  left: 73%;
  top: 30px;
}
@media (width: 1024px) {
  .dropDown {
    left: 700px;
    top: -245px;
  }
}
.dropDownIcon {
  margin-top: 8px;
  border-radius: 50%;
  // width: 50px;
  //height: 50px;
  animation: bounce 1s infinite alternate;
  -webkit-animation: bounce 1s infinite alternate;
  padding-left: 70px !important;
  color: #fff !important;
}
@keyframes bounce {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-15px);
  }
}
@-webkit-keyframes bounce {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-15px);
  }
}
.morelinks.list-group-item > .whats {
  background-color: #90cc42;
}
@media (width: 768px) {
  .whatsapp {
    margin: 20px 16px 12px -49px;
  }
}
// Backedby
.backedBy {
  left: 30%;
  position: relative;
}
@media (max-width: 812px) {
  .backedBy {
    left: 0%;
  }
}
@media (width: 768px) {
  .backedBy {
    left: 30%;
  }
}
// socialmedia
.footer-social {
  margin: 62px -30px 20px 0px;
}
//@media(width:1024px){
// .footer-social{
// margin: 49px -33px 0px -27.5px
// }
//}
@media (width: 768px) {
  .footer-social {
    margin: 98px 0px 0px -19px;
  }
}
// whatsapp
@media (width: 1024px) {
  .whatsapp {
    margin: 18px 10px 20px -20px;
    width: 210px;
  }

  @media (max-width: 812px) {
    .whatsapp {
      margin: 18px 10px 20px -20px;
    }
  }
}
.get_started {
  padding: 90px;
}
@media (max-width: 812px) {
  .get_started {
    padding: 20px;
  }
}
// Farm Metrics
.farmMetrics {
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 0px;
  background-color: #fff;
  //border-style:solid;
  //border-color:rgb(33, 197, 112);
  color: rgb(33, 197, 112);
  text-align: center;
  height: 150px;
  padding: 43px 16px 16px 17px;
}
@media (max-width: 768px) {
  .metricsh {
    font-size: 20px !important;
  }
}
@media (width: 768px) {
  .metricsp {
    font-size: 10px !important;
  }
}
.paymentTab {
  border-color: none !important;
  //background-color: #90CC42;
  padding-left: 20%;
  border-bottom: none;
}
.nav-tabs .nav-link.active {
  border-color: #0d9a49;
}
.nav-tabs .nav-link:hover {
  //border-color:#0D9A49
}
//For the new Components
.AboutImg {
  //background-image: linear-gradient(164deg, #90cc41, #21954a);
  padding: 30px 0px;
  margin: 0px auto;
}
// for referral page
.textfield {
  border-radius: 0px;
  background-color: #fff;
  height: 50px;
}

.referralcard {
  padding: 1rem;
}
@media (max-width: 576px) {
  .referralcard {
    padding: 0.5rem;
  }
}
.referral_cad {
  background-color: #fff;
  height: 404;
  width: 400;
  padding: 30px;
}
.referralFooter {
  text-align: center;
  font-size: 14px;
  color: #fff;
}
@media (max-width: 812px) {
  .referralFooter {
    text-align: center;
    font-size: 7px;
    color: #fff;
  }
}
.referralfigure {
  text-align: center;
  font-size: 12px;
}
@media (max-width: 812px) {
  .referralfigure {
    text-align: center;
    font-size: 8px;
  }
}
// ayoade added there to adjust the button on the 24th of October
.paystackbutton {
  width: 100%;
  padding: 20px;
}
.paywithcard {
  background-color: #f4f4f4;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  border-style: solid;
  border-width: thin;
}
.paywithcardtext {
  position: absolute;
  top: 15px;
  color: #404040;
}
.paywithcardnew {
  background-color: #ffffff;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  border-style: solid;
  border-width: thin;
}
.paywithcardText {
  color: #e0e0e0;
  position: absolute;
  top: 15px;
}
.CopyButton {
  position: absolute;
  right: 20px;
  top: -2px;
  font-size: 14px;
  font-weight: 700;
}
@media (max-width: "812px") {
  .CopyButton {
    position: absolute;
    right: 20px;
    top: -52px;
    font-size: 12px;
    font-weight: 700;
  }
}
@media (max-width: "320px") {
  .CopyButton {
    position: absolute;
    right: 20px;
    top: -46px;
    font-size: 7px;
    font-weight: 700;
  }
}
@media (min-width: "768px") {
  .CopyButton {
    position: absolute;
    right: 20px;
    top: -6px;
    font-size: 8px;
    font-weight: 700;
  }
}

@media (width: "1024px") {
  .CopyButton {
    position: absolute;
    right: 20px;
    top: 2px;
    font-size: 9px;
    font-weight: 700;
  }
}
.referralCode {
  font-size: 16px;
  margin: 17px;
}
@media (max-width: "812px") {
  .referralCode {
    font-size: 13px;
    margin: 12px;
  }
}
@media (min-width: "320px") {
  .referralCode {
    font-size: 11px;
    margin: 12px;
  }
}
@media (min-width: "806px") {
  .referralCode {
    font-size: 13px;
    margin: 12px;
  }
}
// styles for the logo
.ccl-67e0c7f3fe50cf69 {
  display: inline-block;
}
.homefooterInfo {
  background-color: #000;
  max-width: 100%;
  height: 60px;
  padding: 15px 20px;
  cursor: pointer;
  border-radius: 7px;
}
.homefooterList {
  margin: 10px 0px;
}
.thrive-logo {
  margin: 25% 25% 0 0;
}

@media (max-width: 1024px) {
  .thrive-logo {
    margin: 15% 25% 0 0;
  }
}
.thrive-space {
  margin-left: 30px;
}
@media (width: 768px) {
  .thrive-space {
    margin-left: 0px;
  }
}
.thrive-logo-space {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

@media (width: 768px) {
  .thrive-logo-space {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    margin-top: 20%;
  }
}
@media (width: 1024px) {
  .thrive-logo-space {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 50% !important;
  }
}
@media (width: 768px) {
  .thrive-space1 {
    margin-top: 56%;
  }
}
@media (width: 1024px) {
  .thrive-logo {
    margin: 33% 25% 0 0;
  }
}
// For Referral activities
.custom-header {
  padding-top: 20px;
  padding-bottom: 20px;
  /* background-color: rgba(0, 0, 0, 0.03); */
  padding-left: 10px;
  text-align: start;
  font-size: 20px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 7px 8px #0000001a;
}

.titleHighlight {
  border-bottom: 1px solid rgb(51, 168, 71);
}

.image-width {
  width: 250px;
}

.text-style {
  color: #33a847;
  font-weight: 400;
  text-align: center;
  font-size: 34px;
}

.borderRadius {
  border-radius: 15px;
}

.ref_code_button {
  width: 104px;
  height: 32px;
  background-color: #33a847;
  color: white;
  font-size: 15px;
  font-weight: 600;
  border-radius: 8px;
  border: none;
}
.ref_code_button:hover {
  background-color: #b5e378;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.17);
}

.ref_link_button {
  width: 104px;
  height: 32px;
  background-color: #33a847;
  color: white;
  font-size: 15px;
  margin-left: 78px;
  font-weight: 600;
  border-radius: 8px;
  border: none;
}

.social {
  padding: 0px;
}

.social_align {
  margin-left: -30px;
  padding: 0px;
}

.placeholder_text {
  text-align: left;
  font: Medium 32px/62px Montserrat;
  letter-spacing: 0;
  color: #a8a8a8;
  opacity: 1;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.table_border {
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  box-shadow: 0px 7px 8px #0000001a;
}

.ref_code_box {
  background: #f6f8f4 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0000001a;
  border-radius: 8px;
  opacity: 1;
  //height: 50px;
  //width: 400px;
  font-size: 20px;
}

.ref_link_box {
  background: #f6f8f4 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0000001a;
  border-radius: 8px;
  opacity: 1;
  font-size: 20px;
  margin-bottom: 20px;
}

.ref_code_history {
  background: #f6f8f4 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0000001a;
  border-radius: 8px;
  opacity: 1;
  font-size: 20px;
  height: 44px;
}

.ref-text {
  display: flex;
  align-items: center;
  justify-content: center;
  //padding: 0 17%;
}

.ref_code_header {
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  padding-bottom: 15px;
}
@media (max-width: 1024px) {
  .ref_code_header {
    font-size: 12px !important;
  }
}
.Referral_Row {
  padding: 50px 0px;
  justify-content: center;
}
.ref_link_header {
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  padding-bottom: 20px;
  //margin-left: 120px;
}

@media only screen and (min-width: 1280px) {
  .social-style {
    flex: 0 0 10.666667%;
    max-width: 16.666667%;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1277px) {
  .social {
    flex: 0 0 12.666667%;
    max-width: 16.666667%;
  }

  .social_align {
    flex: 0 0 12.666667%;
    max-width: 16.666667%;
  }
  .total-earnings.col {
    margin-top: 25px;
  }
  .first_text {
    font-size: 22px;
  }
  .ref_link_header {
    text-align: center;
    margin-left: 0px;
  }
  .ref-code-container {
    padding-left: 1rem !important;
  }

  .sub_ref_container {
    padding-right: 0px !important;
    padding-left: 14px !important;
  }

  .ref_balance_container {
    margin-top: 25px;
  }
  .placeholder_text {
    font-size: 17px;
  }

  .ref_link_button {
    margin-left: 77px;
  }
  .social-style {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .social {
    flex: 0 0 12.666667%;
    max-width: 16.666667%;
  }

  .social_align {
    flex: 0 0 12.666667%;
    max-width: 16.666667%;
  }
  .total-earnings.col {
    margin-top: 25px;
  }
  .first_text {
    font-size: 22px;
  }
  .ref_link_header {
    text-align: center;
    margin-left: 0px;
  }
  .ref-code-container {
    padding-left: 1rem !important;
  }

  .sub_ref_container {
    padding-right: 0px !important;
    padding-left: 14px !important;
  }

  .ref_balance_container {
    margin-top: 25px;
  }
  .placeholder_text {
    font-size: 17px;
  }

  .ref_link_button {
    margin-left: 63px;
  }
  .social-style {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
}

@media only screen and (min-width: 400px) and (max-width: 500px) {
  .image-width {
    width: 170px;
  }
  .text-style {
    font-size: 15px;
    margin: 0.7em;
  }
  .ref-text {
    padding: 0px;
    margin-left: 0rem !important;
    margin-right: 0rem !important;
    font-size: 12px;
  }

  .ref-bonus {
    margin-top: 24px;
  }
  .row.social-btn-container {
    flex-wrap: nowrap;
  }

  .tagline-container {
    margin-left: 8px;
    text-align: center !important;
  }

  .col-sm-1.social-style {
    width: 26%;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .ref_code_header {
    font-size: 12px;
  }

  .ref-box-wrapper {
    flex-wrap: nowrap;
  }

  .placeholder_text {
    font-size: 15px;
    padding-right: 0px;
  }

  .ref_code_button {
    width: 93px;
    height: 29px;
    font-size: 13px;
  }

  .total-ref-container {
    margin-top: 12px;
    flex-basis: auto;
  }

  // .ref_code_history {
  //   width: 97%;
  // }

  .total-earnings {
    margin-top: 12px;
    flex-basis: auto;
    padding-left: 0px;
  }

  .question_mark_img {
    width: 18px;
    height: 18px;
  }

  // .tot_refs {
  //   width: 122%;
  // }
  .ref_link_header {
    text-align: center;
    font-size: 14px;
    margin-left: 0;
  }
  .placeholder_text.col-sm-8.first_text {
    font-size: 17px;
  }
  .placeholder_text.col-sm-8 {
    font-size: 13px;
    padding-right: 0px;
    margin-bottom: 10px;
  }
  .ref_balance_container {
    margin-top: 62px;
  }
  .justify-content-start.ml-4.mb-3.social-style-wrapper.row {
    margin-left: 0.5em !important;
  }
}
@media (max-width: 812px) {
  .link_code {
    font-size: 9px;
  }
}
@media only screen and (min-width: 360px) and (max-width: 399px) {
  .image-width {
    width: 170px;
  }

  .ref_link_button {
    margin-left: 0px;
  }

  .ref_link_box {
    height: 54px;
  }

  .col.ref_activities_tab {
    margin-top: 24px;
  }

  .col-sm-4.ref_link_btn_container {
    text-align: center;
  }

  .pl-0.pr-4.col.ref-code-container {
    padding-right: 0px !important;
  }

  .text-style {
    font-size: 15px;
    margin: 0.7em;
  }
  .ref-text {
    padding: 0px;
    margin-left: 0rem !important;
    margin-right: 0rem !important;
    font-size: 12px;
  }

  .row.social-btn-container {
    flex-wrap: nowrap;
  }

  .tagline-container {
    margin-left: 8px;
  }

  .col-sm-1.social-style {
    width: 26%;
  }

  .ref_code_header {
    font-size: 12px;
  }

  .ref-box-wrapper {
    flex-wrap: nowrap;
  }

  .placeholder_text {
    font-size: 15px;
    padding-right: 0px;
  }

  .ref_code_button {
    width: 93px;
    height: 29px;
    font-size: 13px;
  }

  .total-ref-container {
    margin-top: 12px;
    flex-basis: auto;
    margin-right: 0px !important;
  }

  // .ref_code_history {
  //   width: 97%;
  // }

  .total-earnings {
    margin-top: 12px;
    flex-basis: auto;
    padding-left: 0px;
    padding-right: 0px !important;
  }

  .question_mark_img {
    width: 18px;
    height: 18px;
  }

  .mt-1.ml-3.mb-4.mr-3.row.ref_and_social_container {
    margin-right: 7px !important;
  }

  .pl-0.pr-4.col.sub_ref_container {
    padding-right: 12px !important;
  }

  // .tot_refs {
  //   width: 126%;
  // }
  .ref_link_header {
    text-align: center;
    font-size: 14px;
    margin-left: 0;
  }
  .placeholder_text.col-sm-8.first_text {
    font-size: 13px;
  }
  .placeholder_text.col-sm-8 {
    font-size: 14px;
    padding-right: 0px;
    margin-top: 0px;
    margin-bottom: 10px;
  }
  .ref_balance_container {
    margin-top: 62px;
  }
  .justify-content-start.ml-4.mb-3.social-style-wrapper.row {
    margin-left: 0.5em !important;
  }
  .col-sm-1.social-style {
    width: 20%;
    font-size: 10px;
    padding: 0px;
    margin-right: 1px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 359px) {
  .image-width {
    width: 170px;
  }

  .ref_link_button {
    margin-left: 0px;
  }

  .ref_link_box {
    height: 54px;
  }

  .col.ref_activities_tab {
    margin-top: 23px;
  }

  .col-sm-4.ref_link_btn_container {
    text-align: center;
    margin-top: 7px;
  }

  .pl-0.pr-4.col.ref-code-container {
    padding-right: 0px !important;
  }

  .text-style {
    font-size: 15px;
    margin: 0.7em;
  }
  .ref-text {
    padding: 0px;
    margin-left: 0rem !important;
    margin-right: 0rem !important;
    font-size: 12px;
  }

  .row.social-btn-container {
    flex-wrap: nowrap;
  }

  // .tagline-container {
  //   margin-left: 8px;
  // }

  .col-sm-1.social-style {
    width: 20%;
    font-size: 8px;
    padding: 0px;
    margin-right: 11px;
  }

  .justify-content-start.ml-4.mb-3.social-style-wrapper.row {
    margin-left: 0.5em !important;
  }

  .ref_code_header {
    font-size: 12px;
  }

  .ref-box-wrapper {
    flex-wrap: nowrap;
  }

  .placeholder_text {
    font-size: 15px;
    padding-right: 0px;
  }

  .ref_code_button {
    width: 72px;
    height: 29px;
    font-size: 9px;
  }

  .col-sm-4.ref-code-btn-container {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .total-ref-container {
    margin-top: 12px;
    flex-basis: auto;
    margin-right: 0px !important;
  }

  // .ref_code_history {
  //   width: 97%;
  // }

  .total-earnings {
    margin-top: 12px;
    flex-basis: auto;
    padding-left: 0px;
    padding-right: 0px !important;
  }

  .question_mark_img {
    width: 18px;
    height: 18px;
  }

  .mt-1.ml-3.mb-4.mr-3.row.ref_and_social_container {
    margin-right: 7px !important;
  }

  .pl-0.pr-4.col.sub_ref_container {
    padding-right: 12px !important;
  }

  // .tot_refs {
  //   width: 126%;
  // }
  .ref_link_header {
    text-align: center;
    font-size: 14px;
    margin-left: 0;
  }
  /* .placeholder_text.col-sm-8.first_text {
      font-size: 10px;
      padding-left: 0;
      margin-left: 14px;
    } */

  .first_text {
    margin-top: 8px;
    font-size: 10px;
    padding-left: 0;
    margin-left: 14px;
  }

  .placeholder_text.col-sm-8 {
    font-size: 12px;
    padding-right: 0px;
    margin-bottom: 14px;
  }
  .ref_balance_container {
    margin-top: 62px;
  }
}
@media (max-width: 812px) {
  .ref_code_history {
    margin-bottom: 15px;
  }
}
@media (max-width: 812px) {
  .ref_code_box {
    margin-bottom: 15px;
  }
}
@media (max-width: 812px) {
  .Referral_Row {
    padding: 10px 0px;
  }
}
.rollover_text {
  color: #a8a8a8;
  font-size: 20px;
}
@media (max-width: 812px) {
  .rollover_text {
    color: #a8a8a8;
    font-size: 15px;
  }
}
// Roll over
.divider {
  width: 0px;
  height: 140px;
  border: 0.8px solid #dbdbdb;
  opacity: 1;
}
/* .headerStyle {
    padding-top: 9px;
    text-align: center;
    font-weight: 500;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  } */

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.rollFundButton {
  background-color: #33a847;
  color: #fff;
  height: 35px;
  width: 180px;
  border-radius: 7px;
  font-weight: 600;
  outline: none;
  border: none;
}

.rollBalance-headers {
  color: #a8a8a8;
}

.rollBalance-price {
  color: #33a847;
}

.table_border {
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
}

.duePayouts {
  border-top: none;
  border-right: none;
  border-left: none;
  background-color: transparent;
  outline: none;
  margin-bottom: -1px;
}
.referrral_container {
  margin: 0px 15px;
}
@media (max-width: 812px) {
  .referrral_container {
    margin: 0px;
  }
}
/* @media only screen and (min-width: 576px) and (max-width: 767px) {
    .divider {
      height: 80px;
    }
    .rollBalance-headers {
      font-size: 14px;
    }
    .rollBalance-price {
      font-size: 14px;
    }
  
    div > div.mt-5.ml-5.col-sm-5 {
      margin-left: 0px !important;
    }
    div > div.mt-5.ml-5.pt-4.pl-5.col {
      margin-left: 0px !important;
    }
  
    div.mx-auto.row > div > div > div > div.mt-5.ml-5.col-sm-5 {
      margin-top: 1rem !important;
    }
    #root
      > div
      > div.wrapper.h-100
      > div.main-panel.userDashboardBg
      > div
      > div
      > div.mt-3.mx-3
      > div.bg-white.ml-4.mr-5.pb-0.pt-4.row
      > div {
      width: 100% !important;
    }
  } */

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .rollBalContainer {
    margin-right: 70px;
  }
  .mt-5.ml-5.rollover-card.col-sm-5 {
    margin-left: 0rem !important;
  }
  .headerAlign {
    margin-right: 3rem !important;
  }
}

@media only screen and (min-width: 411px) and (max-width: 412px) {
  .col-md-12.pl-4.pr-5.col {
    padding-right: 0px !important;
    padding-left: 0px !important;
    margin-left: 8px;
    /* background-color: white; */
  }

  .rollFundButton {
    margin-left: 24px;
  }
  div.mx-auto.row > div {
    padding-left: 0px !important;
  }

  div > div.mt-5.ml-5.col-sm-5 {
    margin-left: 0rem !important;
  }
  div > div.mt-2.col-sm-1 {
    width: 0%;
  }
  .divider {
    display: none;
  }
  div > div.mt-5.ml-5.pt-4.pl-5.col {
    padding-left: 2rem !important;
    margin-left: 0rem !important;
    margin-bottom: 12px;
  }

  .rollover-card {
    padding-left: 76px;
  }
  .rollBalance-price {
    padding-left: 60px;
  }
  div > div.mt-3.mx-3 {
    margin-right: 0px !important;
  }
  .main-panel div > .content {
    padding: 0px 0px 0px 0px;
  }

  .text-right.col.duepayout-mobile {
    padding-top: 23px;
  }
  .bg-white.ml-4.mr-5.pb-0.pt-4.row.headerAlign {
    margin-right: -9px !important;
    margin-left: 9px !important;
  }
  .col-md-12.col-sm-auto.col.subHeaderStyle {
    padding-left: 20px;
    margin-right: 10px;
  }
}

@media only screen and (min-width: 414px) and (max-width: 575px) {
  .col-md-12.pl-4.pr-5.col {
    padding-right: 0px !important;
    padding-left: 0px !important;
    margin-left: 8px;
    /* background-color: white; */
  }

  .rollFundButton {
    margin-left: 24px;
  }
  div.mx-auto.row > div {
    padding-left: 0px !important;
  }

  div > div.mt-5.ml-5.col-sm-5 {
    margin-left: 0rem !important;
  }
  div > div.mt-2.col-sm-1 {
    width: 0%;
  }
  .divider {
    display: none;
  }
  div > div.mt-5.ml-5.pt-4.pl-5.col {
    padding-left: 2rem !important;
    margin-left: 0rem !important;
    margin-bottom: 12px;
  }

  .rollover-card {
    padding-left: 76px;
  }
  .rollBalance-price {
    padding-left: 60px;
  }
  div > div.mt-3.mx-3 {
    margin-right: 0px !important;
  }
  .main-panel div > .content {
    padding: 0px 0px 0px 0px;
  }

  /* .text-right.col.duepayout-mobile {
      padding-top: 23px;
    } */
  .bg-white.ml-4.mr-5.pb-0.pt-4.row.headerAlign {
    margin-right: -9px !important;
    margin-left: 9px !important;
  }
  .col-md-12.col-sm-auto.col.subHeaderStyle {
    padding-left: 20px;
    margin-right: 10px;
  }
}

@media only screen and (min-width: 360px) and (max-width: 400px) {
  col-md-12.pl-4.pr-5.col {
    padding-right: 0px !important;
    padding-left: 0px !important;
    margin-left: 8px !important;
    /* background-color: white; */
  }
  div > div.mx-auto.row {
    margin-right: 14px !important;
  }

  .rollFundButton {
    margin-left: 10px;
  }
  div.mx-auto.row > div {
    padding-left: 0px !important;
  }

  div > div.mt-5.ml-5.col-sm-5 {
    margin-left: 0rem !important;
  }
  div > div.mt-2.col-sm-1 {
    width: 0%;
  }
  .divider {
    display: none;
  }
  div > div.mt-5.ml-5.pt-4.pl-5.col {
    padding-left: 2rem !important;
    margin-left: 0rem !important;
    margin-bottom: 12px;
  }

  .rollover-card {
    padding-left: 54px !important;
  }
  .rollBalance-price {
    padding-left: 42px;
  }
  div > div.mt-3.mx-3 {
    margin-right: 0px !important;
  }
  .main-panel div > .content {
    padding: 0px 0px 0px 0px;
  }

  .text-right.col.duepayout-mobile {
    padding-top: 23px;
  }
  .bg-white.ml-4.mr-5.pb-0.pt-4.row.headerAlign {
    margin-right: 0px !important;
  }
  .col-md-12.col-sm-auto.col.subHeaderStyle {
    padding-left: 20px;
    margin-right: 10px;
  }

  div > div.mt-3.mx-3 {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  div.mt-3 > div.col-md-12.pl-4.pr-5.col {
    padding-right: 0px !important;
  }
}

@media only screen and (min-width: 320px) and (max-width: 321px) {
  .col-md-12.pl-4.pr-5.col {
    padding-right: 0px !important;
    padding-left: 8px !important;
    margin-left: 24px;
    /* background-color: white; */
  }
  .headerAlign {
    margin-right: -1.5rem !important;
  }
  .rollBalance-headers {
    font-size: 1.4em;
  }

  div.mx-auto.row > div {
    padding-left: 0px !important;
  }

  .main-panel div > .content {
    padding: 0px 0px 0px 0px;
  }

  div > div.mt-5.ml-5.col-sm-5 {
    margin-left: 0rem !important;
  }

  div.mt-5.ml-5.rollover-card.col-sm-5 > div.mt-2.pl-4.h3.col {
    margin-bottom: 0px;
  }

  div > div.mt-2.col-sm-1 {
    display: none;
  }
  .divider {
    width: 217px;
    height: 0px;
  }

  div > div.mt-5.ml-5.pt-4.pl-5.col {
    padding-left: 2rem !important;
    //margin-left: 1rem !important;
    margin-top: 0rem !important;
    margin-bottom: 10px;
  }

  .rollover-card {
    padding-left: 45px;
    padding-top: 20px;
  }

  div.mt-5.ml-5.rollover-card.col-sm-5 > div.mt-2.h3.col {
    margin-left: 40px;
  }

  div > div.mt-5.ml-5.rollover-card.col-sm-5 {
    margin-top: 0rem !important;
  }
}
.displayDivider {
  border-left: 1px solid #0000001a;
}
// @media only screen and (min-width: 360px) and (max-width: 812px) {
//   .displayDivider{
//    display: none;
//   }
// }
@media only screen and (min-width: 360px) {
  .headerAlign {
    margin: 0px !important;
  }
}
@media (max-width: 812px) {
  .col .roll_subhead {
    padding: 0px !important;
  }
}
.react-date-picker__wrapper {
  border: thin solid white;
  border-radius: 5px;
  padding: 2px;
  background-color: white;
  box-shadow: 0 1px 15px 1px rgba(39, 39, 39, 0.1);
}
.react-date-picker__wrapper:focus {
  outline: 0px !important;
}
input:focus {
  outline: 0px !important;
}
.search_button_date {
  height: 26px;
  background-color: #91cc42;
  border-radius: 12px;
}
.search_button_text {
  color: #fff;
}
.search_button {
  padding-top: 20px;
}
.search_button_date:focus {
  outline: 0px !important;
}
.search_button_date:hover {
  background-color: #f4faed;
}
.search_button_text:hover {
  color: #3f3f3f;
}
button:focus {
  outline: 0px !important;
}
