@mixin card-icon($color) {
  background: $color 0% 0% no-repeat padding-box;
  border-radius: 11px;
  opacity: 1;
  width: 39px;
  height: 41px;
  display: flex;
  justify-content: center;
  align-items: center;
}
