.checkbox-label {
  display: block;
  position: relative;
  margin: auto;
  cursor: pointer;
  font-size: 22px;
  line-height: 24px;
  height: 24px;
  width: 24px;
  clear: both;
}

.checkbox-label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkbox-label .checkbox-custom {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 24px;
  width: 24px;
  background-color: transparent;
  border-radius: 5px;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  border: 2px solid $light-gray;
}

.checkbox-label input:checked ~ .checkbox-custom {
  background-color: #3bc55224;
  border-radius: 5px;
  -webkit-transform: rotate(0deg) scale(1);
  -ms-transform: rotate(0deg) scale(1);
  transform: rotate(0deg) scale(1);
  opacity: 1;
  border: 2px solid #ffffff;
}

.checkbox-label .checkbox-custom::after {
  position: absolute;
  content: "";
  left: 12px;
  top: 12px;
  height: 0px;
  width: 0px;
  border-radius: 5px;
  border: solid #000000;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(0deg) scale(0);
  -ms-transform: rotate(0deg) scale(0);
  transform: rotate(0deg) scale(0);
  opacity: 1;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
}

.checkbox-label input:checked ~ .checkbox-custom::after {
  -webkit-transform: rotate(45deg) scale(1);
  -ms-transform: rotate(45deg) scale(1);
  transform: rotate(45deg) scale(1);
  opacity: 1;
  left: 8px;
  top: 3px;
  width: 6px;
  height: 12px;
  border: solid #000000;
  border-width: 0 2px 2px 0;
  background-color: transparent;
  border-radius: 0;
}

// .form-check {
//   margin-top: 0.5rem;
// }

// .form-check .form-check-label {
//   display: inline-block;
//   position: relative;
//   cursor: pointer;
//   padding-left: 35px;
//   line-height: 26px;
//   margin-bottom: 0;
//   -webkit-transition: color 0.3s linear;
//   -moz-transition: color 0.3s linear;
//   -o-transition: color 0.3s linear;
//   -ms-transition: color 0.3s linear;
//   transition: color 0.3s linear;
// }

// .radio .form-check-sign {
//   padding-left: 28px;
// }

// .form-check .form-check-sign::before,
// .form-check .form-check-sign::after {
//   content: " ";
//   display: inline-block;
//   position: absolute;
//   width: 20px;
//   height: 20px;
//   left: 0;
//   cursor: pointer;
//   border-radius: 3px;
//   top: 0;
//   // background-color: transparent;
//   background-color: #fff;
//   border: 1px solid $light-gray;
//   -webkit-transition: opacity 0.3s linear;
//   -moz-transition: opacity 0.3s linear;
//   -o-transition: opacity 0.3s linear;
//   -ms-transition: opacity 0.3s linear;
//   transition: opacity 0.3s linear;
// }

// .form-check .form-check-sign::after {
//   font-family: "Nucleo Outline";
//   content: "\ea22";
//   top: 0px;
//   text-align: center;
//   font-size: 10px;
//   opacity: 0;
//   color: $dark-background;
//   border: 0;
//   background-color: inherit;
// }

// .form-check.disabled .form-check-label,
// .form-check.disabled .form-check-label {
//   color: $dark-gray;
//   opacity: 0.5;
//   cursor: not-allowed;
// }

// .form-check input[type="checkbox"],
// .radio input[type="radio"] {
//   opacity: 0;
//   position: absolute;
//   visibility: hidden;
// }

// .form-check input[type="checkbox"]:checked + .form-check-sign::after {
//   opacity: 1;
// }

// .form-control input[type="checkbox"]:disabled + .form-check-sign::before,
// .checkbox input[type="checkbox"]:disabled + .form-check-sign::after {
//   cursor: not-allowed;
// }

// .form-check input[type="checkbox"]:disabled + .form-check-sign,
// .form-check input[type="radio"]:disabled + .form-check-sign {
//   pointer-events: none;
// }

// .form-check-radio .form-check-sign::before,
// .form-check-radio .form-check-sign::after {
//   content: " ";
//   width: 20px;
//   height: 20px;
//   border-radius: 50%;
//   border: 1px solid $light-gray;
//   display: inline-block;
//   position: absolute;
//   left: 3px;
//   top: 3px;
//   padding: 1px;
//   -webkit-transition: opacity 0.3s linear;
//   -moz-transition: opacity 0.3s linear;
//   -o-transition: opacity 0.3s linear;
//   -ms-transition: opacity 0.3s linear;
//   transition: opacity 0.3s linear;
// }

// .form-check-radio input[type="radio"] + .form-check-sign:after,
// .form-check-radio input[type="radio"] {
//   opacity: 0;
// }

// .form-check-radio input[type="radio"]:checked + .form-check-sign::after {
//   width: 4px;
//   height: 4px;
//   background-color: $dark-background;
//   border-color: $dark-background;
//   top: 11px;
//   left: 11px;
//   opacity: 1;
// }

// .form-check-radio input[type="radio"]:checked + .form-check-sign::after {
//   opacity: 1;
// }

// .form-check-radio input[type="radio"]:disabled + .form-check-sign {
//   color: $dark-gray;
// }

// .form-check-radio input[type="radio"]:disabled + .form-check-sign::before,
// .form-check-radio input[type="radio"]:disabled + .form-check-sign::after {
//   color: $dark-gray;
// }
