.container-className {
  display: flex;
}

.custom-page-item {
  padding: 0 8px;
  border: none;
  background-color: transparent;
  outline: none;
  &:hover {
    color: #3bc552;
  }
  &:focus {
    outline: none;
  }
}

.page-active {
  color: #3bc552;
}

.pagination-control {
  margin: 0 5px;
  border-radius: 50%;
  background-color: #eaeaea;
  padding: 5px 8px 8px 8px;
  &:hover {
    cursor: pointer;
    background-color: #3bc552;
  }
  &:focus {
    background-color: #3bc552;
  }
  &:active {
    background-color: #3bc552 !important;
  }
}

.pagination-position {
  margin: 0 auto;
  margin-top: 12px;

  //justify-: stretch;
}

.pagination-container {
  background-color: #ffffff;
  padding: 8px;
  margin-left: 15px;
  margin-right: 15px;
  border-radius: 10px;
  margin-bottom: 15px;
  display: flex;
  justify-content: flex-start;
  overflow-x: scroll;
}
.pagination-text {
  text-align: center;
  font-weight: 600;
}
.pagination-textfield {
  width: 20px;
}
.pagination-jump-btn {
  border: none;
  color: white;
  background-color: #3bc552;
  padding: 4px;
  border-radius: 4px;
}
.pagination-jump {
  margin: 0 1rem;
  display: flex;
  gap: 5px;
  align-items: center;
}
