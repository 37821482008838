.fixed-plugin {
  position: fixed;
  right: 0;
  width: 64px;
  background: rgba(0, 0, 0, .3);
  z-index: 1031;
  border-radius: 8px 0 0 8px;
  text-align: center;
  top: 120px;

  li > a,
  .badge {
    transition: all .34s;
    -webkit-transition: all .34s;
    -moz-transition: all .34s;
  }

  .fa-cog {
    color: #FFFFFF;
    padding: 10px;
    border-radius: 0 0 6px 6px;
    width: auto;
  }

  .dropdown-menu {
    right: 80px;
    left: auto !important;
    top: -52px !important;
    width: 290px;
    border-radius: 0.1875rem;
    padding: 0 10px;
  }

  .dropdown-menu .now-ui-icons {
    top: 5px;
  }

  .dropdown-menu:after,
  .dropdown-menu:before {
    right: 10px;
    margin-left: auto;
    left: auto;
  }

  .fa-circle-thin {
    color: #FFFFFF;
  }

  .active .fa-circle-thin {
    color: #00bbff;
  }

  .dropdown-menu > .active > a,
  .dropdown-menu > .active > a:hover,
  .dropdown-menu > .active > a:focus {
    color: #777777;
    text-align: center;
  }

  img {
    border-radius: 0;
    width: 100%;
    height: 100px;
    margin: 0 auto;
  }

  .dropdown-menu li > a:hover,
  .dropdown-menu li > a:focus {
    box-shadow: none;
  }

  .badge {
    border: 3px solid #FFFFFF;
    border-radius: 50%;
    cursor: pointer;
    display: inline-block;
    height: 23px;
    margin-right: 5px;
    position: relative;
    width: 23px;
  }

  .badge.active,
  .badge:hover {
    border-color: #00bbff;
  }

  .badge-blue {
    background-color: $brand-info;
  }
  .badge-green {
    background-color: $brand-success;
  }
  .badge-orange {
    background-color: $brand-primary;
  }
  .badge-yellow {
    background-color: $brand-warning;
  }
  .badge-red {
    background-color: $brand-danger;
  }

  h5 {
    font-size: 14px;
    margin: 10px;
  }

  .dropdown-menu li {
    display: block;
    padding: 18px 2px;
    width: 25%;
    float: left;
  }

  li.adjustments-line,
  li.header-title,
  li.button-container {
    width: 100%;
    height: 50px;
    min-height: inherit;
  }

  li.button-container {
    height: auto;

    div {
      margin-bottom: 5px;
    }
  }

  #sharrreTitle {
    text-align: center;
    padding: 10px 0;
    height: 50px;
  }

  li.header-title {
    height: 30px;
    line-height: 25px;
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
  }

  .adjustments-line {
    p {
      float: left;
      display: inline-block;
      margin-bottom: 0;
      font-size: 1em;
      color: #3C4858;
    }

    a {
      color: transparent;

      .badge-colors {
        position: relative;
        top: -2px;
      }

      a:hover,
      a:focus {
        color: transparent;
      }
    }

    .togglebutton {
      text-align: center;

      .label-switch {
        position: relative;
        left: -10px;
        font-size: $font-size-mini;
        color: $default-color;

        &.label-right {
          left: 10px;
        }
      }

      .toggle {
        margin-right: 0;
      }
    }

    .dropdown-menu > li.adjustments-line > a {
      padding-right: 0;
      padding-left: 0;
      border-bottom: 1px solid #ddd;
      border-radius: 0;
      margin: 0;
    }
  }

  .dropdown-menu {
    > li {
      & > a.img-holder {
        font-size: 16px;
        text-align: center;
        border-radius: 10px;
        background-color: #FFF;
        border: 3px solid #FFF;
        padding-left: 0;
        padding-right: 0;
        opacity: 1;
        cursor: pointer;
        display: block;
        max-height: 100px;
        overflow: hidden;
        padding: 0;

        img {
          margin-top: auto;
        }
      }

      a.switch-trigger:hover,
      & > a.switch-trigger:focus {
        background-color: transparent;
      }

      &:hover,
      &:focus {
        > a.img-holder {
          border-color: rgba(0, 187, 255, 0.53);;
        }
      }
    }

    > .active > a.img-holder,
    > .active > a.img-holder {
      border-color: #00bbff;
      background-color: #FFFFFF;
    }

  }

  .btn-social {
    width: 50%;
    display: block;
    width: 48%;
    float: left;
    font-weight: 600;
  }

  .btn-social {
    i {
      margin-right: 5px;
    }

    &:first-child {
      margin-right: 2%;
    }
  }

  .dropdown {
    .dropdown-menu {
      -webkit-transform: translateY(-15%);
      -moz-transform: translateY(-15%);
      -o-transform: translateY(-15%);
      -ms-transform: translateY(-15%);
      transform: translateY(-15%);
      top: 27px;
      opacity: 0;

      transform-origin: 0 0;

      &:before {
        border-bottom: .4em solid rgba(0, 0, 0, 0);
        border-left: .4em solid rgba(0, 0, 0, 0.2);
        border-top: .4em solid rgba(0, 0, 0, 0);
        right: -16px;
        top: 46px;
      }

      &:after {
        border-bottom: .4em solid rgba(0, 0, 0, 0);
        border-left: .4em solid #FFFFFF;
        border-top: .4em solid rgba(0, 0, 0, 0);
        right: -16px;
      }

      &:before,
      &:after {
        content: "";
        display: inline-block;
        position: absolute;
        top: 74px;
        width: 16px;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
      }
    }

    &.show .dropdown-menu {
      opacity: 1;

      -webkit-transform: translateY(-13%);
      -moz-transform: translateY(-13%);
      -o-transform: translateY(-13%);
      -ms-transform: translateY(-13%);
      transform: translateY(-13%);

      transform-origin: 0 0;
    }
  }

  .bootstrap-switch {
    margin: 0;
  }
  .gh-btn,
  .gh-count,
  .gh-ico {
    float: left;
  }
  .gh-btn,
  .gh-count {
    padding: 2px 5px 2px 4px;
    color: #333;
    text-decoration: none;
    white-space: nowrap;
    cursor: pointer;
    border-radius: 3px;
  }
  .gh-btn {
    background-color: #eee;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #fcfcfc), color-stop(100%, #eee));
    background-image: -webkit-linear-gradient(top, #fcfcfc 0, #eee 100%);
    background-image: -moz-linear-gradient(top, #fcfcfc 0, #eee 100%);
    background-image: -ms-linear-gradient(top, #fcfcfc 0, #eee 100%);
    background-image: -o-linear-gradient(top, #fcfcfc 0, #eee 100%);
    background-image: linear-gradient(to bottom, #fcfcfc 0, #eee 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fcfcfc', endColorstr='#eeeeee', GradientType=0);
    background-repeat: no-repeat;
    border: 1px solid #d5d5d5;
  }
  .gh-btn:hover,
  .gh-btn:focus {
    text-decoration: none;
    background-color: #ddd;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #eee), color-stop(100%, #ddd));
    background-image: -webkit-linear-gradient(top, #eee 0, #ddd 100%);
    background-image: -moz-linear-gradient(top, #eee 0, #ddd 100%);
    background-image: -ms-linear-gradient(top, #eee 0, #ddd 100%);
    background-image: -o-linear-gradient(top, #eee 0, #ddd 100%);
    background-image: linear-gradient(to bottom, #eee 0, #ddd 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#eeeeee', endColorstr='#dddddd', GradientType=0);
    border-color: #ccc;
  }
  .gh-btn:active {
    background-image: none;
    background-color: #dcdcdc;
    border-color: #b5b5b5;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15);
  }
  .gh-ico {
    width: 14px;
    height: 14px;
    margin-right: 4px;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjQwcHgiIGhlaWdodD0iNDBweCIgdmlld0JveD0iMTIgMTIgNDAgNDAiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMTIgMTIgNDAgNDAiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxwYXRoIGZpbGw9IiMzMzMzMzMiIGQ9Ik0zMiAxMy40Yy0xMC41IDAtMTkgOC41LTE5IDE5YzAgOC40IDUuNSAxNS41IDEzIDE4YzEgMC4yIDEuMy0wLjQgMS4zLTAuOWMwLTAuNSAwLTEuNyAwLTMuMiBjLTUuMyAxLjEtNi40LTIuNi02LjQtMi42QzIwIDQxLjYgMTguOCA0MSAxOC44IDQxYy0xLjctMS4yIDAuMS0xLjEgMC4xLTEuMWMxLjkgMC4xIDIuOSAyIDIuOSAyYzEuNyAyLjkgNC41IDIuMSA1LjUgMS42IGMwLjItMS4yIDAuNy0yLjEgMS4yLTIuNmMtNC4yLTAuNS04LjctMi4xLTguNy05LjRjMC0yLjEgMC43LTMuNyAyLTUuMWMtMC4yLTAuNS0wLjgtMi40IDAuMi01YzAgMCAxLjYtMC41IDUuMiAyIGMxLjUtMC40IDMuMS0wLjcgNC44LTAuN2MxLjYgMCAzLjMgMC4yIDQuNyAwLjdjMy42LTIuNCA1LjItMiA1LjItMmMxIDIuNiAwLjQgNC42IDAuMiA1YzEuMiAxLjMgMiAzIDIgNS4xYzAgNy4zLTQuNSA4LjktOC43IDkuNCBjMC43IDAuNiAxLjMgMS43IDEuMyAzLjVjMCAyLjYgMCA0LjYgMCA1LjJjMCAwLjUgMC40IDEuMSAxLjMgMC45YzcuNS0yLjYgMTMtOS43IDEzLTE4LjFDNTEgMjEuOSA0Mi41IDEzLjQgMzIgMTMuNHoiLz48L3N2Zz4=');
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .gh-count {
    position: relative;
    display: none; /* hidden to start */
    margin-left: 4px;
    background-color: #fafafa;
    border: 1px solid #d4d4d4;
  }
  .gh-count:hover,
  .gh-count:focus {
    color: #4183C4;
  }
  .gh-count:before,
  .gh-count:after {
    content: '';
    position: absolute;
    display: inline-block;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
  }
  .gh-count:before {
    top: 50%;
    left: -3px;
    margin-top: -4px;
    border-width: 4px 4px 4px 0;
    border-right-color: #fafafa;
  }
  .gh-count:after {
    top: 50%;
    left: -4px;
    z-index: -1;
    margin-top: -5px;
    border-width: 5px 5px 5px 0;
    border-right-color: #d4d4d4;
  }
  .github-btn-large {
    height: 30px;
  }
  .github-btn-large .gh-btn,
  .github-btn-large .gh-count {
    padding: 3px 10px 3px 8px;
    font-size: 16px;
    line-height: 22px;
    border-radius: 4px;
  }
  .github-btn-large .gh-ico {
    width: 20px;
    height: 20px;
  }
  .github-btn-large .gh-count {
    margin-left: 6px;
  }
  .github-btn-large .gh-count:before {
    left: -5px;
    margin-top: -6px;
    border-width: 6px 6px 6px 0;
  }
  .github-btn-large .gh-count:after {
    left: -6px;
    margin-top: -7px;
    border-width: 7px 7px 7px 0;
  }
  .github-btn {
    display: inline-block;
    .gh-btn {
      .gh-ico {
        margin-top: 3px;
      }
    }
  }
  .SocialMediaShareButton {
    display: inline-block;
  }
}
