.navbar {
  padding-top: $navbar-padding-base;
  padding-bottom: $navbar-padding-base;
  margin-bottom: 20px;
  a {
    vertical-align: middle;

    &:not(.btn):not(.dropdown-item) {
      color: $white-color;
    }

    &.dropdown-item {
      color: $default-color;
    }
  }

  &.bg-white {
    .input-group .form-control,
    .input-group.no-border .form-control {
      color: $default-color;

      @include placeholder() {
        color: $default-color;
      }
    }
    .input-group-addon i {
      color: $default-color;
      opacity: 0.5;
    }
  }

  .form-group,
  .input-group {
    margin: 0;
    margin-left: -3px;
    margin-right: 5px;
    .form-group-addon,
    .input-group-addon {
      color: $white-color;
    }
    &.no-border {
      .form-control {
        color: $white-color;

        @include placeholder() {
          color: $white-color;
        }
      }
    }
  }

  p {
    display: inline-block;
    margin: 0;
    line-height: 1.8em;
    font-size: 1em;
    font-weight: 400;
  }

  &.navbar-absolute {
    position: absolute;
    width: 100%;
    padding-top: 10px;
    z-index: 1029;
  }

  .documentation & {
    &.fixed-top {
      left: 0;
      width: initial;
    }
  }

  .navbar-wrapper {
    display: inline-flex;
    align-items: center;
    // justify-content: flex-start;
    .navbar-minimize {
      padding-right: 10px;

      .btn {
        margin: 0;
      }
    }

    .navbar-toggle {
      .navbar-toggler {
        padding-left: 0;
      }

      &:hover {
        & .navbar-toggler-bar.bar2 {
          width: 22px;
        }
      }
    }
  }

  .navbar-nav.home {
    .nav-link {
      border-radius: $btn-round-radius;
    }

    .nav-link.active {
      color: $thrive-tone-2;
    }
    .nav-item.active .nav-link:not(.btn),
    .nav-item .nav-link:not(.btn):focus,
    .nav-item .nav-link:not(.btn):hover,
    .nav-item .nav-link:not(.btn):active {
      border-radius: $btn-round-radius;
    }
  }
  .navbar-nav {
    &.navbar-logo {
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 49px;
      top: -4px;
    }

    .nav-link.btn {
      padding: $padding-btn-vertical $padding-btn-horizontal;
      &.btn-lg {
        padding: $padding-large-vertical $padding-large-horizontal;
      }
      &.btn-sm {
        padding: $padding-small-vertical $padding-small-horizontal;
      }
    }
    .primary {
      background-color: lighten($primary-color, 40%);
    }

    .nav-link {
      // text-transform: uppercase;
      font-size: $font-size-small;
      padding: $padding-base-vertical $padding-base-horizontal;
      line-height: $line-height-nav-link;
      margin-right: 3px;
      // width: 105px;
      text-align: center;
      font-family: "Montserrat", sans-serif;
      font-weight: 500;
      color: $dark-green-color;

      i.fa + p,
      i.now-ui-icons + p {
        margin-left: 6px;
      }

      i.fa,
      i.now-ui-icons {
        font-size: 18px;
        position: relative;
        top: 3px;
        text-align: center;
        width: 21px;
      }

      i.now-ui-icons {
        top: 4px;
        font-size: 16px;
      }

      &.profile-photo {
        .profile-photo-small {
          width: 27px;
          height: 27px;
        }
      }

      &.disabled {
        opacity: 0.5;
        color: $white-color;
      }
    }

    // .nav-item.active .nav-link:not(.btn),
    // .nav-item .nav-link:not(.btn):focus,
    // .nav-item .nav-link:not(.btn):hover,
    // .nav-item .nav-link:not(.btn):active {
    //   background-color: $opacity-2;
    //   border-radius: $border-radius-small;
    //   color: $white-color;
    // }
  }

  .logo-container {
    width: 27px;
    height: 27px;
    overflow: hidden;
    margin: 0 auto;
    border-radius: 50%;
    border: 1px solid transparent;
  }

  .navbar-brand {
    text-transform: uppercase;
    font-size: $font-size-small;
    padding-top: $padding-base-vertical;
    padding-bottom: $padding-base-vertical;
    line-height: $line-height-nav-link;
  }

  .navbar-toggler {
    width: 37px;
    height: 27px;
    vertical-align: middle;
    outline: 0;
    cursor: pointer;

    & .navbar-toggler-bar.navbar-kebab {
      width: 3px;
      height: 3px;
      border-radius: 50%;
      margin: 0 auto;
    }
  }

  .button-dropdown {
    .navbar-toggler-bar:nth-child(2) {
      width: 17px;
    }
  }

  &.navbar-transparent {
    background-color: $transparent-bg !important;
    box-shadow: none;
    color: $white-color;
  }

  // &.bg-white:not(.navbar-transparent) {
  a:not(.dropdown-item):not(.btn) {
    color: $dark-green-color;

    &.disabled {
      opacity: 0.5;
      color: $default-color;
    }

    &.highlight {
      color: white;
      background-color: $thrive-tone-2;
    }

    &.active {
      color: $thrive-tone-2;
    }
  }

  .button-bar {
    background: $default-color;
  }

  .nav-item.active .nav-link:not(.btn),
  .nav-item .nav-link:not(.btn):focus,
  .nav-item .nav-link:not(.btn):hover,
  .nav-item .nav-link:not(.btn):active {
    // background-color: $opacity-gray-8;
    color: $thrive-tone-1;
  }

  .nav-item .nav-link.highlight:not(.btn):focus,
  .nav-item .nav-link.highlight:not(.btn):hover,
  .nav-item .nav-link.highlight:not(.btn):active {
    background-color: $thrive-tone-1;
    color: white;
  }

  .logo-container {
    border: 1px solid $default-color;
  }
  // }
}

@media (max-width: 992px) {
  .navbar {
    a:not(.dropdown-item):not(.btn) {
      &.highlight {
        // position: absolute;
        width: 150px;
        height: 60px;
        text-align: center;
        line-height: 44px;
      }
    }
    .navbar-nav {
      .nav-link {
        width: initial;
        text-align: left;
        font-size: 1.1429em;
      }
    }
  }
}

.backend {
  min-height: 100px;
  margin-bottom: 0px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
}

.bg-default {
  background-color: $default-color !important;
}

.bg-primary {
  background-color: $primary-color !important;
}

.bg-info {
  background-color: $info-color !important;
}

.bg-success {
  background-color: $success-color !important;
}

.bg-danger {
  background-color: $danger-color !important;
}

.bg-warning {
  background-color: $warning-color !important;
}

.bg-white {
  background-color: $white-color !important;
}
